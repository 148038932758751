<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <nav id="site-navigation"
      class="has-branding main-navigation grid-container nav-align-right sub-menu-right"
      aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
      <div class="inside-navigation grid-container">
        <div class="navigation-branding">
          <div class="site-logo">
            <a href="/" title="GBWhatsApp" rel="home">
              <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp"
                title="GBWhatsApp">
            </a>
          </div>
          <p class="main-title" itemprop="headline">
            <a href="/" rel="home">
              GBWhatsApp
            </a>
          </p>
        </div>
        
        <!-- 顶部导航栏  -->
        <div id="primary-menu" class="main-nav">
          <ul id="menu-primary-menu" class="menu sf-menu">
            <li id="menu-item-32"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
              <a href="/" aria-current="page">Home</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a :href="downloadURL">Download</a>
            </li>
            <!-- @click="gotodownload()" -->
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="blogs">Blogs</a>
            </li>
            <!-- 多语言 -->
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <div class="language-selector">
                <select id="language-select" v-model="selectedLanguage"
                  placeholder="Select Language" @change="changeLanguage">
                  <option v-for="(data, i) in lang" :key="`lang_${i}`" :value="data.lang">
                    {{ data.val }}
                  </option>
                </select>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <article>
              <div class="inside-article">
                <div class="entry-content" itemprop="text">
                  <div class="wp-block-cover alignwide has-parallax" style="min-height: 200px">
                    <span aria-hidden="true"
                      class="wp-block-cover__background has-background-dim-80 has-background-dim wp-block-cover__gradient-background has-background-gradient"
                      style="background: linear-gradient(180deg, rgb(2, 247, 229) 0%, rgb(0, 0, 0) 99%)" />
                    <!-- <div role="img" class="wp-block-cover__image-background wp-image-622 has-parallax" style="background-position: 50% 50%; background-image: url(wp-content/uploads/2022/12/BG-2K.webp)"></div> -->
                    <div class="wp-block-cover__inner-container">
                      <!-- 首图logo -->
                      <div class="wp-block-image ic sdw">
                        <figure class="aligncenter size-full">
                          <img decoding="async" width="512" height="512" src="../assets/logo.webp"
                            alt="GB WhatsApp APK" class="wp-image-615">
                        </figure>
                      </div>

                      <h1 id="gbwhatsapp-pro" class="has-text-align-center hd">
                        GBWhatsApp
                      </h1>

                      <div class="code-block code-block-4"
                        style="margin: 8px auto; text-align: center; display: block; clear: both">
                        <p class="has-text-align-center v">
                          Version: <strong>18.10</strong> | Size: <strong>77MB</strong>
                        </p>
                      </div>

                      <p class="has-text-align-center d">
                        GBWhatsApp app is a modified version of the official WhatsApp. The difference
                         between GB WhatsApp and WhatsApp lies in custom features. GB WhatsApp app 
                         provides more custom features which attract millions of users. Those appealing
                          features include hide double ticks, save status, DND mode, unread messages, 
                          anti-revoke messages, unlimited themes and fonts and others. 
                      </p>

                      <div
                        class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                        <div class="wp-block-button">
                          <a class="wp-block-button__link wp-element-button"
                            @click="downloadClick">Download GBWhatsApp New
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="beesads" />

                  <!-- Table of Contents -->
                  <div id="toc_container" class="no_bullets">
                    <section class="table-content" v-show="!tableHide">
                      <div class="table-content-title" @click="tableHide = !tableHide">
                        Table of Contents
                        <img src="../assets/minus.svg" alt="minus">
                      </div>

                      <ul class="toc_list">
                        <li>
                          <a href="#TBC_0"><span class="toc_number toc_depth_1">1.</span> GB WhatsApp Pro V18.10 was released!</a>
                        </li>
                        <li>
                          <a href="#TBC_1"><span class="toc_number toc_depth_1">2.</span> What is GB WhatsApp and GBWhatsApp Pro?</a>
                        </li>
                        <li>
                          <a href="#TBC_2"><span class="toc_number toc_depth_2">3.</span> Why people
                            prefer using GB WhatsApp？</a>
                        </li>
                        <li>
                          <a href="#TBC_3"><span class="toc_number toc_depth_3">4.</span> What is the
                            difference between GB WhatsApp pro and WhatsApp?</a>
                        </li>
                        <li>
                          <a href="#TBC_4"><span class="toc_number toc_depth_4">5.</span> GB WhatsApp Hot
                            Features</a>
                        </li>
                        <li>
                          <a href="#TBC_5"><span class="toc_number toc_depth_5">6.</span> Download GB
                            WhatsApp Apk Latest Version 18.10</a>
                        </li>
                        <li>
                          <a href="#TBC_6"><span class="toc_number toc_depth_6">7.</span>
                            Screenshot of GB WhatsApp</a>
                        </li>
                        <li>
                          <a href="#TBC_7"><span class="toc_number toc_depth_7">8.</span> GB WhatsApp
                            Update</a>
                        </li>
                        <li>
                          <a href="#TBC_8"><span class="toc_number toc_depth_8">9.</span> GB WhatsApp Alternatives</a>
                        </li>
                        <li>
                          <a href="#TBC_9"><span class="toc_number toc_depth_9">10.</span> Is GBWhatsApp Safe?</a>
                        </li>
                        <li>
                          <a href="#TBC_10"><span class="toc_number toc_depth_10">11.</span> FAQ About
                            GBWhatsApp Pro</a>
                        </li>
                        <li>
                          <a href="#TBC_11"><span class="toc_number toc_depth_11">12.</span>
                            How to spy on others WhatsApp without touching their phone?</a>
                        </li>
                        <li>
                          <a href="#TBC_12"><span class="toc_number toc_depth_12">13.</span> Conclusion</a>
                        </li>
                      </ul>
                      
                    </section>
                    <section class="table-content hide" v-show="tableHide">
                      <div class="table-content-title" @click="tableHide = !tableHide">
                        Table of Contents
                        <img src="../assets/plus.svg" alt="plus">
                      </div>
                    </section>
                    
                  </div>

                  <h2 class="h">
                    <span id="TBC_0"> GB WhatsApp Pro V18.10 was released!
                    </span>
                  </h2>
                  <h3>❗❗GB WhatsApp 18.10 was released❗❗ </h3>
                  <p>
                    Users can update this new version now! What's new in this 
                    version? Like former updates, the latest version of GB WhatsApp
                     also updated anti-ban protection system and added an introduction 
                     to guide users fix accounts ban issue. Users can update their GB WhatsApp old version now. 
                  </p>

                  <!-- <div
                    class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                    <div class="wp-block-button">
                      <a class="wp-block-button__link wp-element-button"
                        @click="gotodownload()">Download GBWhatsApp New</a>
                    </div>
                  </div> -->


                  <h2 class="h">
                    <span id="TBC_1">What is GB WhatsApp and GBWhatsApp Pro? </span>
                  </h2>

                  <p>
                    You're probably familiar with WhatsApp, but have you heard of 
                    GB WhatsApp? It's an enhanced version of the standard WhatsApp.
                     There's also WhatsApp Pro. Both of the two apps are modified app. 
                     They provide premium features based on the official WhatsApp. 
                     While some websites introduce the two apps separatly. But, the truth is,
                      they share the same APK with slight feature differences. There is no big
                       differences between GBWhatsApp APK and GB WhatsApp Pro APK. So, you can 
                       choose to download either one based on your preference.
                  </p>

                  <h2 class="h">
                    <span id="TBC_2">Why people prefer using GB WhatsApp？ </span>
                  </h2>

                  <p>
                    There's little difference between the original WhatsApp, GB WhatsApp, 
                    GB WhatsApp Pro, and <a class="jump-url" target="_blank" href="https://fmmodapk.com/">FM WhatsApp</a> for sending messages. 
                    However, WhatsApp is official and available for many different platforms. 
                    The rest are only available for Android systems. So, why people prefer using 
                    GB WhatsApp Pro APK? Actually it's because GB WhatsApp Pro keeps updating for 
                    these years. And, it adds extra custom features and more privacy options.
                  </p>

                  <div
                    class="is-layout-flex wp-container-5 wp-block-columns are-vertically-aligned-center">
                    <div class="is-layout-flow wp-block-column is-vertically-aligned-center">
                      <p class="fet">
                        <i class="fa fa-check" /> Bluetick
                      </p>

                      <p class="fet">
                        <i class="fa fa-check-double" /> Double Tick
                      </p>

                      <p class="fet">
                        <i class="fa fa-dot-circle" /> Being online
                      </p>

                      <p class="fet">
                        <i class="fa fa-keyboard" /> Typing Status
                      </p>

                      <p class="fet">
                        <i class="fa fa-stop-circle" /> Recording status
                      </p>
                    </div>

                    <div class="is-layout-flow wp-block-column is-vertically-aligned-center">
                      <p class="fet">
                        <i class="fa fa-microphone" /> Microphone settings
                      </p>

                      <p class="fet">
                        <i class="fa fa-history" /> Scheduling messages
                      </p>

                      <p class="fet">
                        <i class="fa fa-reply-all" /> Auto reply
                      </p>

                      <p class="fet">
                        <i class="fa fa-cloud-download-alt" /> Save Status
                      </p>

                      <p class="fet">
                        <i class="fa fa-plane" /> DND Mode
                      </p>
                    </div>

                    <div class="is-layout-flow wp-block-column">
                      <p class="fet">
                        <i class="fa fa-ban" /> Anti-ban service
                      </p>

                      <p class="fet">
                        <i class="fa fa-fingerprint" /> Fingerprint Lock
                      </p>

                      <p class="fet">
                        <i class="fa fa-cogs" /> Themes &amp; Fonts
                      </p>

                      <p class="fet">
                        <i class="fa fa-user-circle" /> Icon Change
                      </p>

                      <p class="fet">
                        <i class="fa fa-phone-alt" /> Disable Call
                      </p>
                    </div>
                  </div>

                  <h2 class="h">
                    <span id="TBC_3"> What is the difference between GB WhatsApp pro and WhatsApp?
                    </span>
                  </h2>
                  <p>
                    After the brief introduction of GB WhatsApp, here we list the clear comparision of
                     GB WhatsApp and WhatsApp. Comparing those features differences, you can decide which one to use.
                  </p>

                  <figure class="wp-block-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Feature</th>
                          <th>GBWhatsApp Pro</th>
                          <th>WhatsApp</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>DND Mode</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Ghost Mode</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>File Sending Limit</td>
                          <td>999MB</td>
                          <td>100MB</td>
                        </tr>
                        <tr>
                          <td>Image Quality</td>
                          <td>Original</td>
                          <td>Compressed</td>
                        </tr>
                        <tr>
                          <td>Themes</td>
                          <td>4000+</td>
                          <td>3</td>
                        </tr>
                        <tr>
                          <td>Auto Reply</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Unread Message</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Bulk Messages</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Change Font</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Anti-Revoke</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Hide Double Ticks</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Change Blue Ticks</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Security Lock</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Icon Change</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                      </tbody>
                    </table>
                  </figure>

                  <!-- Features -->
                  <h2 class="h">
                    <span id="TBC_4"> GB WhatsApp Hot Features </span>
                  </h2>

                  <div class="is-layout-flex wp-container-8 wp-block-columns">
                    <div class="is-layout-flow wp-block-column">
                      <h3 class="h3">Translation</h3>
                      <p>
                        GB WhatsApp Pro now allows users to translate more messages within a chat, making communication more accessible across languages.
                      </p>

                      <h3 class="h3">Hide the Profile Icon</h3>
                      <p>
                        In the latest version of GB WhatsApp Pro, you can hide your profile icon from others. This feature is now available for added privacy.
                      </p>

                      <h3 class="h3">Go to the First Message</h3>
                      <p>
                        Need to revisit important messages from the beginning of a chat? With this feature, you no longer need to scroll manually. Just click the option, and you'll jump directly to the first message in seconds.
                      </p>

                      <h3 class="h3">Themes</h3>
                      <p>
                        GB WhatsApp Pro offers a wide range of themes, allowing you to change your app's layout whenever you like. With over 4000+ themes available, this is one of the top reasons why users choose GB WhatsApp.
                      </p>

                      <h3 class="h3">DND Mode</h3>
                      <p>
                        The built-in Do Not Disturb mode in GB WhatsApp Pro lets you disable the internet connection for the app only, so you won’t be interrupted by WhatsApp messages while using other apps on your phone.
                      </p>

                      <h3 class="h3">App Lock</h3>
                      <p>
                        GB WhatsApp Pro includes an app lock feature, allowing you to secure your messages by requiring a pattern, PIN, password, or fingerprint to access the app. This ensures your chats remain private, even if others have access to your phone.
                      </p>
                    </div>

                    <div class="is-layout-flow wp-block-column">
                      <h3 class="h3">Auto Reply</h3>
                      <p>
                        Set up automatic replies to messages when you're unable to respond immediately. You can use this feature for business purposes without needing to download GB WhatsApp Business APK.
                      </p>

                      <h3 class="h3">Bulk Sender</h3>
                      <p>
                        Send unlimited messages to multiple contacts at once with the Bulk Sender feature, making it easy to share information with all your contacts in just a few clicks.
                      </p>

                      <h3 class="h3">Hide Status Seen</h3>
                      <p>
                        With a single click, you can view and download your contacts’ statuses without letting them know you've seen them, providing you with more control over your privacy.
                      </p>

                      <h3 class="h3">Anti-Revoke</h3>
                      <p>
                        The anti-revoke feature in GB WhatsApp Pro ensures that even if someone deletes a message after sending it, you’ll still be able to see it by default.
                      </p>

                      <h3 class="h3">Send Maximum Pictures</h3>
                      <p>
                        GB WhatsApp Pro allows you to send over 100 pictures at a time, surpassing the official WhatsApp limit. You can easily share multiple photos without any hassle.
                      </p>

                      <h3 class="h3">Send Original Images</h3>
                      <p>
                        With GB WhatsApp Pro, you can send images in their original quality, ensuring that your photos remain sharp and clear after being sent.
                      </p>

                      <h3 class="h3">Download Status</h3>
                      <p>
                        Save pictures and videos from statuses uploaded by your contacts directly within GB WhatsApp Pro. There’s no need for any third-party apps, and this feature is available for free.
                      </p>
                    </div>
                  </div>

                  <h2 class="h">
                    <span id="TBC_5"> Download GB WhatsApp Apk Latest Version 18.10 </span>
                  </h2>
                  <div class="wp-block-image ic">
                    <figure class="aligncenter size-full">
                      <img decoding="async" width="512" height="512" src="../assets/logo.webp"
                        alt="GBWhatsApp Pro" class="wp-image-615">
                    </figure>
                  </div>
                  <p>
                    GBWA Pro keeps updating the apk file for Android users. Recently, it updated a new version
                     18.10 and added some new features including hide profile icon, more translation language and
                      go to the first message in a chat. So, what is the app information of GB WhatsApp? Read the excel below: 
                  </p>

                  <figure class="wp-block-table is-style-regular">
                    <table class="has-fixed-layout">
                      <tbody>
                        <tr>
                          <td>Name</td>
                          <td>GBWhatsApp Pro</td>
                        </tr>
                        <tr>
                          <td>Version</td>
                          <td>
                            <div class="code-block code-block-3" style="margin: 8px 0; clear: both">
                              18.10
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Size</td>
                          <td>77M</td>
                        </tr>
                        <tr>
                          <td>Developer</td>
                          <td><a href="https://gbapksdownload.com/">AlexMODS</a></td>
                        </tr>
                        <tr>
                          <td>Updated</td>
                          <td>2 Days Ago</td>
                        </tr>
                      </tbody>
                    </table>
                  </figure>

                  <div
                    class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                    <div class="wp-block-button">
                      <a class="wp-block-button__link wp-element-button"
                        @click="gotodownload()">Download GBWhatsApp Pro</a>
                    </div>
                  </div>

                  <p>
                    <span style="font-weight: bold;">Guide</span>: Click the button to download GB WhatsApp Pro. Your phone may notify you that the APK
                     file is from a third-party source and could pose potential risks. To proceed, you'll need to 
                     trust the APK. Go to your phone's settings and enable permission to install from unknown sources.
                  </p>

                  <h2 class="h">
                    <span id="TBC_6"> Screenshot of GB WhatsApp</span>
                  </h2>

                  <div class="wp-block-image">
                    <figure class="aligncenter size-full">
                      <picture>
                        <source srcset="../assets/Screenshot.webp" type="image/webp">
                        <img decoding="async" loading="lazy" width="1000" height="563"
                          src="../assets/Screenshot.webp" alt="GB Screenshot" class="wp-image-48">
                      </picture>
                    </figure>
                  </div>

                  <h2 class="h">
                    <span id="TBC_7"> GB WhatsApp Update </span>
                  </h2>

                  <p>
                    If the GB WhatsApp on your phone is still an old version of GB WhatsApp 2023, 
                    you should uninstall it and install the new version. There are two methods to update the new version.
                  </p>

                  <li>
                    First, visit the official GBWhatsApp APK download website to obtain it. You can also download it on 
                    our website homepage. Follow the guide to install the app. 
                  </li>
                  <li>
                    Second, Go to settings and check the current version of GB WhatsApp. If the current version 
                    is an old one, you can then tap on the "GB WhatsApp Update" button and wait for downloading. 
                  </li>

                  <h2 class="h">
                    <span id="TBC_8"> GB WhatsApp Alternatives </span>
                  </h2>
                  <p>
                    There is not only one modified app of WhatsApp. If GB WhatsApp or GB WhatsApp Pro is not able to 
                    use on your Android, you can try the alternatives below:
                  </p>
                  <li>FM WhatsApp</li>
                  <li>WhatsApp Plus</li>
                  <li>OB WhatsApp</li>
                  <li>OG WhatsApp</li>
                  <li>YO WhatsApp</li>
                  <li>Aero WhatsApp</li>
                  <li>Gold WhatsApp</li>
                  <li>Blue WhatsApp</li>

                
                  <h2 class="h">
                    <span id="TBC_9"> Is GBWhatsApp safe? </span>
                  </h2>

                  <p>
                    The security of a modified APP is the first thing most people concern. <br>

                    Based on these years of promoting GB WhatsApp, we haven't found any user is 
                    suffering personal information loss. Only a small number of people complain 
                    about chat data loss or ban issues. So, we can say GBWhatsApp is safe. <br>

                    GB WhatsApp has developed to the point where there are many websites that provide download services.
                     To use GB WhatsApp safely, we recommend users to choose a reliable site and always get the updated 
                     information from it. Our site can be your first choice, because there is no risks in GB WhatsApp APK we provide. <br>

                    If you're still not trust the third-party apk, you can use a new phone number to register and login
                     GB WhatsApp app to enjoy those unlimited features. 
                  </p>


                  <h2 class="h">
                    <span id="TBC_10"> FAQ about GBWhatsApp Pro </span>
                  </h2>
                  <div class="schema-faq wp-block-yoast-faq-block">
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">My account got banned, how to unban?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        You can link GB WhatsApp as a companion device for WhatsApp. Alternatively, search for methods on YouTube. There are many helpful solutions available.
                      </p>
                    </div>
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">How can I recover my banned GB WhatsApp account?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        Download the updated GB WhatsApp APK and try again. Version 18.10 added a guide to successfully log in.
                      </p>
                    </div>
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">Can I use GB Pro and Official WhatsApp on the same phone?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        Yes, you can use both on the same device, but with different accounts.
                      </p>
                    </div>
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">Can I transfer WhatsApp data to GBWhatsApp?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        Yes, go to the local drive on your Android phone and find the WhatsApp data. Copy the files to the GB WhatsApp folder. For more details, check out our blog.
                      </p>
                    </div>
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">Will my number get banned by using GBWA?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        No, this was an old bug. GBWhatsApp is now 100% safe, and WhatsApp’s <a href="https://www.whatsapp.com/legal/updates/terms-of-service/?lang=en">Terms of Service</a> apply.
                      </p>
                    </div>
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">Can I hide GBWhatsApp from my phone?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        You can change the GBWA icon, so no one can tell it's WhatsApp.
                      </p>
                    </div>
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">Can we use GB WhatsApp for business purposes?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        Yes, the auto-reply feature is great for business use, though the Store option will not be available.
                      </p>
                    </div>
                  </div>



                  <h2 class="h">
                    <span id="TBC_11"> How to spy on others Whatsapp without touching their phone?
                    </span>
                  </h2>

                  <p>
                    Though GB WhatsApp is a modified version of WhatsApp, it's developed just for some premium
                     features. Users can use it for more custom features, but spy feature is not available. 
                     This absence of a spy feature underscores the importance of privacy and security within the app. 
                     Users can feel confident that their personal conversations and data remain protected, as GB WhatsApp
                      does not facilitate any unauthorized access of user activity.
                  </p>


            
                  <h2 class="h">
                    <span id="TBC_12"> Conclusion </span>
                  </h2>

                  <p>
                    For those looking for a more personalized and feature-rich WhatsApp experience, GBWhatsApp Pro is an excellent choice. 
                    With its extensive range of features, it's easy to see why millions of users around the globe prefer it over the original
                     WhatsApp. To stay informed about future updates, be sure to save or bookmark this page.
                  </p>


                  <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
                </div>
              </div>
            </article>
          </main>
        </div>
      </div>
    </div>

    <div id="comment-box">
      <div class="title">
        Leave a Comment
      </div>
      <input v-model="comment.name" type="text" class="nameClass" placeholder="your name">
      <input v-model="comment.email" type="email" class="emailClass" placeholder="Email">
      <textarea v-model="comment.content" class="contentClass" />
      <button class="submitClass" @click="submitClick">
        Submit
      </button>
      <div class="commentList-box">
        <div v-for="item in showCommentList" :key="item.time" class="commentClass">
          <img src="@/assets/logo.webp" alt="">
          <div class="container">
            <div class="commentBoxClass">
              <div>
                <span class="commentName">{{ item.name }}</span>
                <span class="commentTime">{{ item.time }}</span>
              </div>
              <span class="commentContent">{{ item.content }}</span>
            </div>
            <div class="triangle" />
          </div>
        </div>
      </div>
      <button class="moreClass" @click="moreClick">
        {{ showText }}
      </button>
    </div>

    <div id="snippet-box">
      <div class="snippet-title">
        Summary
      </div>

      <div itemscope="" itemtype="https://schema.org/SoftwareApplication">
        <div class="aio-info">
          <div class="snippet-label-img snippet-clear">
            Author Rating
          </div>
          <div class="snippet-data-img">
            <span class="star-img"><img src="@/assets/1star.png" alt="1star"><img
                src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img
                src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
                alt="1star"></span>
          </div>
          <div class="snippet-clear" />
          <div class="aggregate_sec" itemprop="aggregateRating" itemscope=""
            itemtype="https://schema.org/AggregateRating">
            <div class="snippet-label-img">
              Aggregate Rating
            </div>
            <div class="snippet-data-img">
              <span itemprop="ratingValue">3.65</span> based on
              <span class="rating-count" itemprop="ratingCount">46258</span>
              votes
            </div>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Software Name
          </div>
          <div class="snippet-data-img">
            <span itemprop="name">GBWhatsApp APK</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Operating System
          </div>
          <div class="snippet-data-img">
            <span itemprop="operatingSystem">Android</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Software Category
          </div>
          <div class="snippet-data-img">
            <span itemprop="applicationCategory">App</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Price
          </div>
          <div class="snippet-data-img">
            <span itemprop="offers" itemscope="" itemtype="https://schema.org/Offer">
              <span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Landing Page
          </div>
          <div class="snippet-data-img">
            <a itemprop="featureList" href="https://gbwhatapks.com/">https://gbwhatapks.com/</a>
          </div>
          <div class="snippet-clear" />
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="#">Privacy Policy</a>
          </div>
          <div class="copyright-bar">
            2022 © All Rights Reserved <strong><a href="/">GBWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      Download GBWhatsApp New
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
// import utlisMethods from '@/utlis/global.js';
import { allCommentList } from '@/utlis/allCommentList.js';
export default {
  name: 'Home',
  data () {
    return {
      pageName: 'home',
      tableHide: true,
      comment: {
        name: '',
        email: '',
        content: '',
      },
      apk: null,
      clickNum: 0,
      selectedLanguage: 'en',
      lang: [
        { lang: 'en', val: '🇺🇸 English' },
        { lang: 'ar', val: '🇦🇪 اَلْعَرَبِيَّةُ' },
        { lang: 'id', val: '🇮🇩 Bahasa Indonesia' },
        { lang: 'pt', val: '🇵🇹 Português' },
        { lang: 'es', val: '🇪🇸 Español' },
        { lang: 'hi', val: '🇮🇳 हिंदी' },
      ],
    };
  },
  computed: {
    allCommentList () {
      const regularTime = Date.parse('2023/11/24 00:00:00');
      let currentTime = new Date().getTime();
      let Difference = Math.floor((currentTime - regularTime) / (3 * 24 * 60 * 60 * 1000));
      return allCommentList.slice(0, 20 + Difference).sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()) || [];
    },
    downloadURL () {
      // var params = window.location.search;
      // return `/downloadpage${params}`;
      if (typeof window === 'undefined') {
        // Server-side rendering logic
        return '/downloadpage'; // Adjust this based on your server-side logic
      } else {
        // Client-side rendering logic
        let params = window.location.search;
        return `/downloadpage${params}`;
      }
    },
    showText () {
      if (this.clickNum < Math.ceil(this.allCommentList.length / 5) - 1) {
        return 'Click Here To Load More Comments';
      } else {
        return 'No More Comments';
      }
    },
    showCommentList () {
      return this.allCommentList.slice(0, (this.clickNum + 1) * 5) || [];
    },
  },
  mounted () {
    this.getOfficalApk();

    // window.adsTag.renderInterstitial(54982);
  },
  methods: {
    moreClick () {
      if (this.clickNum < Math.ceil(this.allCommentList.length / 5) - 1) {
        this.clickNum += 1;
      }
    },
    submitClick () {
      this.$Dialog
        .confirm({
          title: 'Tip',
          message: 'Your comment has been submitted successfully and will be displayed below after review.',
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
        })
        .then(() => {
          this.comment.name = '';
          this.comment.email = '';
          this.comment.content = '';
        })
        .catch(() => {
          console.log('取消');
        });
    },
    changeLanguage (lang) {
      console.log(lang);
      // 处理语言选择变化
      // 这里可以添加其他处理逻辑，例如将语言保存到cookie或localStorage中
      this.$router.push({ name: `${this.selectedLanguage}-gb` });
    },
    downloadClick () {
      this.$router.push({ name: 'download' });
    },
    getOfficalApk (type = 1) {
      this.$server
        .getOfficalApk({
          domain: 'gbwhatsapp.chat',
          appName: 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    gotodownload () {
      // // const params = new URLSearchParams(window.location.href.search)
      // var params = "";
      // if (typeof window !== "undefined") {
      // 	params = window.location.search;
      // }
      // // window.location.href = "/downloadpage" + params;
      // return `/downloadpage${params}`;
      // utlisMethods.download(
      // 	"https://file.cmpc.fun/upapp/1699966982678/GBWhatsApp_2.23.20.632_gw_11_14_2101_sec_sign.apk"
      // );
      window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;

      // this.$global.download(this.apk);
    },
    gotoblog () {
      window.location.href = '/blogs';
    },
    jump (url) {
      window.location.href = url;
    },
  },
};
</script>
